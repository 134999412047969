import React from "react";
import {
  AiOutlineStop,
  AiOutlineUserDelete,
  AiOutlineSearch,
} from "react-icons/ai";
import TextInput from "../../../TextInput/TextInput";
import ClientNotification from "../../Components/ClientNotification/ClientNotification";
import AdminNavigation from "../../Components/AdminNavigation/AdminNavigation";

function AdminNotifications() {
  return (
    <div className="w-[100%] h-[90%] flex items-center justify-start flex-col laptop:mt-8">
      <AdminNavigation />

      <div className="h-[100%] w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-bold text-3xl mb-8">Notifications</h1>
        <TextInput placeholder="search" iconRight={() => <AiOutlineSearch />} />
        <div className="flex items-center justify-center w-[100%]">
          <div className="flex justify-start items-start flex-col mr-[2%] ml-[2%] h-[100%] mt-8 overflow-x-auto overflow-y-auto">
            <ClientNotification
              name="Username"
              plan="Subscription Plan"
              due="Due payment"
              icon1={() => <p className="w-[24px] h-[24px] w-[50%]">Block</p>}
              icon2={() => <p className="w-[24px] h-[24px] w-[50%]">Remove</p>}
              className={"mb-8 h-[50px]"}
            />
            <ClientNotification
              name="Floyd Dixon"
              plan="200$"
              due="24/8/2024"
              img={true}
              icon1={() => <AiOutlineStop className="w-[24px] h-[24px]" />}
              icon2={() => (
                <AiOutlineUserDelete className="w-[24px] h-[24px]" />
              )}
              className={"mb-6 h-[30px]"}
            />
            <ClientNotification
              name="Floyd Dixon"
              plan="200$"
              due="24/8/2024"
              img={true}
              icon1={() => <AiOutlineStop className="w-[24px] h-[24px]" />}
              icon2={() => (
                <AiOutlineUserDelete className="w-[24px] h-[24px]" />
              )}
              className={"mb-6 h-[30px]"}
            />
            <ClientNotification
              name="Floyd Dixon"
              plan="200$"
              due="24/8/2024"
              img={true}
              icon1={() => <AiOutlineStop className="w-[24px] h-[24px]" />}
              icon2={() => (
                <AiOutlineUserDelete className="w-[24px] h-[24px]" />
              )}
              className={"mb-6 h-[30px]"}
            />
            <ClientNotification
              name="Floyd Dixon"
              plan="200$"
              due="24/8/2024"
              img={true}
              icon1={() => <AiOutlineStop className="w-[24px] h-[24px]" />}
              icon2={() => (
                <AiOutlineUserDelete className="w-[24px] h-[24px]" />
              )}
              className={"mb-6 h-[30px]"}
            />
            <ClientNotification
              name="Floyd Dixon"
              plan="200$"
              due="24/8/2024"
              img={true}
              icon1={() => <AiOutlineStop className="w-[24px] h-[24px]" />}
              icon2={() => (
                <AiOutlineUserDelete className="w-[24px] h-[24px]" />
              )}
              className={"mb-6 h-[30px]"}
            />
            <ClientNotification
              name="Floyd Dixon"
              plan="200$"
              due="24/8/2024"
              img={true}
              icon1={() => <AiOutlineStop className="w-[24px] h-[24px]" />}
              icon2={() => (
                <AiOutlineUserDelete className="w-[24px] h-[24px]" />
              )}
              className={"mb-6 h-[30px]"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminNotifications;
