import { APIProvider, Map, useMap } from "@vis.gl/react-google-maps";
import { Polyline } from "../PolyLine/PolyLine";
import React, { useEffect, useState } from "react";
import { NOCHOSENLINE } from "../../../../constants";

const CenterMap = ({ center, zoom }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;
    map.setCenter(center);
    map.setZoom(zoom);
  }, [map, center, zoom]);

  return <></>;
};

export default function MapContainer({
  mapPosition,
  lines,
  zoom,
  hilightedLine,
}) {
  const [changedZoom, setChangedZoom] = useState(0);
  const [changedCenter, setChangedCenter] = useState({ lat: 0, lng: 0 });
  const [chosenLine, setChosenLine] = useState();
  useEffect(() => {
    setChosenLine(hilightedLine);
  }, [hilightedLine]);
  useEffect(() => {
    setChangedZoom(zoom);
  }, [zoom]);
  useEffect(() => {
    setChangedCenter(mapPosition);
  }, [mapPosition]);

  function renderHighlightLine() {
    if (lines === undefined) return;
    if (chosenLine === undefined) return;
    let position = [];
    if (chosenLine !== NOCHOSENLINE)
      lines[chosenLine].geolocations.forEach((element) => {
        position = [...position, { lat: element[0][0], lng: element[0][1] }];
      });
    return (
      <Polyline
        key={chosenLine}
        path={position}
        strokeColor="white"
        zIndex={10}
        strokeWeight={5}
      />
    );
  }

  function renderPolyLines() {
    if (lines === undefined) return;
    let organisedLines = [];
    lines.forEach((line) => {
      let newLine = [];
      line.geolocations.forEach((element) => {
        // eslint-disable-next-line
        if (newLine.length == 0) {
          newLine = [
            element[1][0],
            line.line_id,
            { lat: element[0][0], lng: element[0][1] },
          ];
          // eslint-disable-next-line
        } else if (newLine[0] == element[1][0]) {
          newLine = [...newLine, { lat: element[0][0], lng: element[0][1] }];
        } else {
          organisedLines = [...organisedLines, newLine];
          newLine = [
            element[1][0],
            line.line_id,
            newLine[newLine.length - 1],
            { lat: element[0][0], lng: element[0][1] },
          ];
        }
      });
      // eslint-disable-next-line
      if (newLine.length != 0) organisedLines = [...organisedLines, newLine];
    });
    return organisedLines.map((line, key) => {
      let position = line.slice(2);
      let color = "";
      switch (line[0]) {
        case "1":
          color = "yellow"; // shallow
          break;
        case "2":
          color = "green"; // good
          break;
        case "3":
          color = "gray"; // optimal
          break;
        case "4":
          color = "orange"; // bad
          break;
        case "5":
          color = "red"; // really bad
          break;
        default:
          color = "black";
          break;
      }
      return <Polyline key={line[1]} path={position} strokeColor={color} />;
    });
  }

  return (
    <APIProvider apiKey={""}>
      <Map
        defaultZoom={0}
        defaultCenter={{ lat: 0, lng: 0 }}
        mapTypeId={"satellite"}
      >
        {renderPolyLines()}
        {renderHighlightLine()}
      </Map>
      <CenterMap center={changedCenter} zoom={changedZoom} />
    </APIProvider>
  );
}
