import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../AuthContext";
import { Outlet, useLocation } from "react-router-dom";

function Background({ adminPanel }) {
  const { isAuthenticated, isAdmin } = useContext(AuthContext);
  const [access, setAccess] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setAccess(isAuthenticated);
  }, [isAuthenticated]);

  function renderIfAuthorized() {
    if (location.pathname === "/") {
      return <Outlet />;
    }
    if (access) {
      if (adminPanel && !isAdmin) {
        return (
          <div className="flex items-center justify-center w-[100%] h-[100%] flex-col">
            <h1>You have no access!</h1>
            <h1>You're either not authorized or not logged in yet.</h1>
          </div>
        );
      } else {
        return <Outlet />;
      }
    } else {
      return (
        <div className="flex items-center justify-center w-[100%] h-[100%]">
          <h1>checking for access</h1>
        </div>
      );
    }
  }

  return (
    <div className="h-screen w-screen flex items-center justify-center font-inter relative overflow-hidden">
      <div className="bg-background bg-no-repeat bg-right bg-cover h-[100%] w-[100%] absolute top-0"></div>
      <div className="bg-white bg-opacity-80 h-[100%] w-[100%] absolute pointer-events-none"></div>
      <div className="w-[100%] h-[100%] z-0 overflow-y-auto overflow-x-hidden">
        {renderIfAuthorized()}
      </div>
    </div>
  );
}

export default Background;
