export const data = [
  {
    summary: "What type of file do we accept?",
    details: "We accept XML files.",
  },
  {
    summary: "What should the XML file contain?",
    details:
      "The XML file should contain the GPS origin coordinates, survey points of the topography, and the lateral ulnes design.",
  },
  {
    summary: "What parameters should we submit?",
    details: [
      "All parameters should be numbers. These parameters include:",
      "<ul>",
      "<li>Optimal depth</li>",
      "<li>Maximum depth</li>",
      "<li>Minimum depth</li>",
      "<li>Distance from outlet to optimal</li>",
      "<li>For percentage calculations</li>",
      "</ul>",
      "Based on your experience, you should submit:",
      "<ul>",
      "<li>Minimum optimal percentage</li>",
      "<li>Maximum optimal percentage</li>",
      "<li>Minimum bad percentage</li>",
      "<li>Maximum bad percentage</li>",
      "</ul>",
      "Shallow and peak represent the extreme bad lowest and maximum values that you cannot accept.",
    ],
  },
  {
    summary: "What do the colors mean in the circles and map?",
    details: [
      "<ol>",
      "<li>Gray: Optimal depth</li>",
      "<li>Green: Good depth</li>",
      "<li>Orange: Bad depth</li>",
      "<li>Red: Really bad depth</li>",
      "<li>Black: Peak depth</li>",
      "<li>Yellow: Shallow depth</li>",
      "</ol>",
    ],
  },
  {
    summary: "What is the meaning of map complexity?",
    details:
      "Map complexity is an algorithm that analyzes the topography submitted through the XML file. It retrieves the survey points and calculates the watersheds for the topography. Based on this analysis, it scales the map from 1 to 10, where 10 is very complex and 1 is not complex.",
  },
  {
    summary:
      "The map is too big and the code takes some minutes to finish running. What can I do?",
    details:
      "You can check your previous maps and verify your ulnes until a rectangle pops up, indicating that your map is done with a green color.",
  },
];
