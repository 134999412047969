import React, { useState } from "react";
import Menu from "../../../Menu/Menu";
import Button from "../../../Button/Button";
import TextInput from "../../../TextInput/TextInput";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import AdminNavigation from "../../Components/AdminNavigation/AdminNavigation";
function CreateUser() {
  const [username, setUsername] = useState();
  const [first_name, setFirstname] = useState();
  const [last_name, setLastname] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [errors, setErrors] = useState();
  const [added, setAdded] = useState(false);

  async function addUser() {
    if (confirmPassword !== password) {
      setErrors({
        body: {
          password: ["password and confirm password are different try again"],
        },
      });
      return;
    }
    const url = `${process.env.REACT_APP_API_URL}/auth/users/`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        username,
        password,
        first_name,
        last_name,
        email,
      }),
    };
    fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => {
        if (data.status !== 201) {
          setAdded(false);
          setErrors(data);
        } else {
          setAdded(true);
          setErrors({});
        }
      });
  }

  return (
    <div className="w-[100%] h-[90%] flex items-center justify-start flex-col laptop:mt-8">
      <AdminNavigation />
      <div className="h-[100%] w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-bold text-3xl mb-8">Create User</h1>
        <div className="flex justify-center items-center h-[100%] w-screen flex-col">
          {added && (
            <h1 className="bg-green-500 p-[4px] mb-2 rounded text-white">
              User added successfully
            </h1>
          )}
          <Menu className="rounded-2xl w-[30%] min-w-[300px] text-white h-[80%] min-h-[550px]">
            <div className="flex justify-evenly items-center h-[100%] flex-col">
              <TextInput
                className="flex-col"
                label="First name"
                placeholder="example"
                errorMsg={errors?.body?.first_name}
                onChange={(e) => setFirstname(e.target.value)}
              />
              <TextInput
                className="flex-col"
                label="Last name"
                placeholder="example"
                errorMsg={errors?.body?.last_name}
                onChange={(e) => setLastname(e.target.value)}
              />
              <TextInput
                className="flex-col"
                label="Email"
                placeholder="example@gmail.com"
                errorMsg={errors?.body?.email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextInput
                className="flex-col"
                label="Username"
                placeholder="example"
                errorMsg={errors?.body?.username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextInput
                className="flex-col"
                label="Password"
                placeholder="password123!@#"
                errorMsg={errors?.body?.password}
                isSensitive={true}
                iconRight={() => (
                  <AiOutlineEyeInvisible className="w-[16px] h-[16px]" />
                )}
                onChange={(e) => setPassword(e.target.value)}
              />
              <TextInput
                className="flex-col"
                label="Confirm Password"
                placeholder="password123!@#"
                isSensitive={true}
                iconRight={() => (
                  <AiOutlineEyeInvisible className="w-[16px] h-[16px]" />
                )}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <Button
                text="Create"
                buttonClass="w-[150px] bg-transparent border-2 border-white mt-[15px]"
                onClick={() => addUser()}
              />
            </div>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export default CreateUser;
