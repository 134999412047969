import React, { useState, forwardRef } from "react";
import { AiOutlineEye } from "react-icons/ai";

function TextInput(
  {
    label,
    placeholder,
    className,
    inputClass,
    iconLeft,
    iconRight,
    isSensitive,
    labelClass,
    onChange,
    errorMsg,
    value,
  },
  ref
) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div
      className={`text-white flex items-center justify-center mb-2 ${className}`}
    >
      {label !== undefined && (
        <div className="w-full">
          <p className={`w-fill text-left text-sm ${labelClass}`}>{label}</p>
        </div>
      )}
      <div className="relative w-full">
        {iconLeft !== undefined && (
          <div
            className={
              "w-min border-black text-black bg-white border-[1px] rounded-full absolute left-[-24px] bottom-[-4px]"
            }
          >
            {iconLeft()}
          </div>
        )}

        <input
          defaultValue={value ? value : undefined}
          ref={ref}
          onChange={(e) => {
            if (onChange !== undefined) onChange(e);
          }}
          type={isSensitive && !showPassword ? "password" : "text"}
          placeholder={placeholder}
          className={`text-black rounded w-full px-[10px] focus:outline-none ${inputClass}`}
        />

        {iconRight !== undefined && (
          <div
            className={
              "w-min text-black bg-white absolute right-[5px] bottom-[15%]"
            }
            onClick={() => {
              if (isSensitive) setShowPassword(!showPassword);
            }}
          >
            {!showPassword ? iconRight() : <AiOutlineEye />}
          </div>
        )}
      </div>
      {errorMsg !== undefined && errorMsg !== "" && (
        <div className="w-[100%] relative drop-shadow">
          <div
            className="w-0 h-0 
      border-l-[10px] border-l-transparent
      border-b-[10px] border-b-red-500
      border-r-[10px] border-r-transparent
      absolute left-[10px] top-[1px] rounded-full"
          ></div>
          <h1 className="mt-2 w-[100%] bg-red-500 text-white-500 pl-[4px]">
            {errorMsg}
          </h1>
        </div>
      )}
    </div>
  );
}

export default forwardRef(TextInput);
