import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Button from "../../../Button/Button";

function AboutUs() {
  return (
    <div className="w-[100%] h-[90%] flex items-center justify-start flex-col mt-8">
      <div className="flex items-center justify-end w-[90%]">
        <Navbar />
      </div>
      <div className="h-[90%] w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-bold text-3xl mb-8">About Us</h1>
        <div className="flex justify-start items-center flex-col mr-[2%] ml-[2%] w-[100%]">
          <div className="mb-[75px] flex justify-center items-center flex-col">
            <h1 className="text-2xl font-bold text-center w-[100%]">
              Maximize Your Land’s Potential
            </h1>
            <div className="flex laptop:justify-between laptop:items-center laptop:flex-row flex-col items-center justify-center laptop:mt-[75px] mt-[30px] w-[90%]">
              <p className="laptop:text-left text-center mt-5 laptop:w-[50%] w-[90%]">
                We proudly equip farmers with the tools and support needed to
                maximize their land’s potential. Our mission is to provide
                top-tier drainage solutions that reclaim unproductive land,
                enhance soil health, and ensure optimal crop growth. Luft & Sons
                Farm Drainage offers expert drainage system design,
                installation, maintenance services, and more. Our range of
                services provides the solutions you need, whether you’re facing
                drainage issues or looking to optimize your land’s productivity.
              </p>
              <div className="laptop:w-[500px] laptop:h-[300px] w-[300px] h-[300px] border-black border-8 laptop:mt-0 mt-[50px]"></div>
            </div>
          </div>
          <div className="flex justify-center items-center flex-col laptop:w-[50%] w-[90%] text-center mt-5">
            <h1 className="text-2xl font-bold">RESPONSIBLE LAND MANAGEMENT</h1>
            <p className="mt-5">
              Farmers rely on us to enhance their land, and we take this
              responsibility seriously. Our team works to exceed expectations
              while minimizing environmental impact. We believe in
              problem-solving drainage solutions that promote sustainable land
              management and preservation. Our innovative technologies and
              techniques align with our commitment to sustainability.
            </p>
          </div>
          <div className="flex justify-center items-center flex-col w-[50%] text-center mt-10 laptop:mb-20 mb-[100px]">
            <p>
              Book a consultation with our experts and explore the best
              solutions for your farm.
            </p>
            <div className="flex justify-between items-center mt-[50px] w-[500px] laptop:flex-row flex-col">
              <Button
                text="View Our History"
                buttonClass="w-[150px] border-2 border-black laptop:mt-0 mt-[20px]"
              />
              <Button
                text="View Our Team"
                buttonClass="w-[150px] border-2 border-black laptop:mt-0 mt-[20px]"
              />
              <Button
                text="Join Our Team"
                buttonClass="w-[150px] border-2 border-black laptop:mt-0 mt-[20px]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
