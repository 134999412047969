import React, { forwardRef } from "react";
import ValueCircle from "../../../ValueCircle/ValueCircle";
import Chart from "react-apexcharts";

const PDFReport = forwardRef(({ data, mapName }, ref) => {
  function calculateActualBushels() {
    let percentage = 0;
    if (data.good > 0) percentage += 2.5;
    if (data.bad > 0) percentage += 5;
    if (data.really_bad > 0) percentage += 10;
    percentage = 30 - percentage;
    let bushels = (percentage / 100) * 230 + 230;
    return bushels;
  }
  return (
    // <div>
    <div className="h-[0] overflow-hidden">
      <div ref={ref} className="h-[1604px] w-[1240px]">
        <div id="header">
          <h1 className="text-left text-3xl font-bold mb-8 ml-2">
            Field Flow 360
          </h1>
          <h1 className="text-left text-3xl font-bold mb-8 ml-2">{mapName}</h1>
        </div>
        <div id="section1">
          <h1 className="text-center text-4xl font-bold mb-[150px]">
            Drainage Optimal Efficiency Calculator
          </h1>
          <div className="flex flex-row items-center justify-center">
            <div className="flex flex-row flex-wrap justify-start items-center w-[50%]">
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  // come here
                  title={`${data.optimal_boundiries_floor} <= Optimal <= ${data.optimal_boundiries_ceiling}`}
                  // totalDistance={totalDistances?.optimal.toFixed(2) + " feet"}
                  data={data.optimal + "%"}
                  className="bg-gray-600 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  title={`${data.good_boundiries_floor_first} < Good <= ${data.good_boundiries_ceiling_first}\n And \n${data.good_boundiries_floor_second} < Good <= ${data.good_boundiries_ceiling_second}`}
                  totalDistance={data?.good_distance.toFixed(2) + " feet"}
                  data={data.good + "%"}
                  className="bg-green-500 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  title={`${data.bad_boundiries_floor} < Bad <= ${data.bad_boundiries_ceiling}`}
                  totalDistance={data?.bad_distance.toFixed(2) + " feet"}
                  data={data.bad + "%"}
                  className="bg-orange-500 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  title={`${data.really_bad_boundiries_ceiling} < Really Bad `}
                  totalDistance={data?.really_bad_distance.toFixed(2) + " feet"}
                  data={data.really_bad + "%"}
                  className="bg-red-500 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  title={`Shallow < ${data.shallow_boundiries_floor}`}
                  totalDistance={data?.shallow_distance.toFixed(2) + " feet"}
                  data={data.shallow + "%"}
                  className="bg-yellow-500 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  title="Number of Lateral Connections"
                  data={data.lateral_connections}
                  className="bg-gradient-to-r from-pink-500 to-yellow-500 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  title="Area"
                  data={data.area?.toFixed(2) + " acres"}
                  className="bg-gradient-to-r from-blue-300 to-yellow-300 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
              <div className="w-[33%] flex items-center justify-center">
                <ValueCircle
                  title="Map Complexity / 10"
                  data={data?.map_complexity}
                  className="bg-gradient-to-r from-green-300 to-red-300 rounded-none"
                  textClassName="rounded-none"
                />
              </div>
            </div>
            <div className="flex flex-row justify-center items-center w-[50%]">
              <div className="w-[700px]">
                <Chart
                  options={{
                    chart: {
                      type: "donut",
                    },
                    colors: [
                      "#666666",
                      "#2DF562",
                      "#F5872B",
                      "#F50726",
                      "#F5C31C",
                      "#000000",
                    ],
                    // come here
                    labels: [
                      `${data.optimal_boundiries_floor} <= Optimal <= ${data.optimal_boundiries_ceiling}`,
                      `${data.good_boundiries_floor_first} < Good <= ${data.good_boundiries_ceiling_first}\n And \n${data.good_boundiries_floor_second} < Good <= ${data.good_boundiries_ceiling_second}`,
                      `${data.bad_boundiries_floor} < Bad <= ${data.bad_boundiries_ceiling}`,
                      `${data.really_bad_boundiries_ceiling} < Really Bad `,
                      `Shallow < ${data.shallow_boundiries_floor}`,
                    ],
                  }}
                  series={[
                    data.optimal,
                    data.good,
                    data.bad,
                    data.really_bad,
                    data.shallow,
                  ]}
                  type="donut"
                ></Chart>
              </div>
            </div>
          </div>
        </div>
        <div id="section2">
          <div className="flex flex-row items-center justify-between mt-[50px] text-center text-2xl w-[100%]">
            <div className="w-[33%] flex items-center justify-center">
              <ValueCircle
                title="CSR"
                data="83"
                className="bg-black rounded-none w-[413px]"
                textClassName="rounded-none w-[90%]"
              />
            </div>
            <div className="w-[33%] flex items-center justify-center">
              <ValueCircle
                title="Area"
                data={data.area?.toFixed(2) + " acres"}
                className="bg-black rounded-none w-[413px]"
                textClassName="rounded-none w-[90%]"
              />
            </div>
            <div className="w-[33%] flex items-center justify-center">
              <ValueCircle
                title="Corn"
                data="4.46"
                className="bg-black rounded-none w-[413px]"
                textClassName="rounded-none w-[90%]"
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-center h-[500px] text-center text-3xl">
            <div className="w-[33%] h-[100%] pt-8">
              <h1 className="mb-8 h-[100px]">Without Drainage </h1>
              <div className="border-8 border-black p-2 w-[400px] flex items-center flex-col justify-evenly h-[200px]">
                <h1>
                  Revenue:{" "}
                  {Math.round(
                    data.area && data.area * 230 * 4.46
                  ).toLocaleString() + " $"}
                </h1>
                <h1>
                  Bushels:{" "}
                  {Math.round(data.area && data.area * 230).toLocaleString()}
                </h1>
              </div>
            </div>
            <div className="w-[33%] h-[100%] pt-8">
              <h1 className="mb-8 h-[100px]">
                With Drainage <span className="text-green-500">Actual</span>
              </h1>
              <div className="border-8 border-black p-2 w-[400px] flex items-center flex-col justify-evenly h-[200px]">
                <h1>
                  Revenue:
                  {Math.round(
                    data.area && data.area * calculateActualBushels() * 4.46
                  ).toLocaleString() + " $"}
                </h1>
                <h1>
                  Bushels:
                  {Math.round(
                    data.area && data.area * calculateActualBushels()
                  ).toLocaleString()}
                </h1>
              </div>
            </div>
            <div className="w-[33%] h-[100%] pt-8">
              <h1 className="mb-8 h-[100px]">
                With Drainage <span className="text-blue-500">Theoretical</span>
              </h1>
              <div className="border-8 border-black p-2 w-[400px] flex items-center flex-col justify-evenly h-[200px]">
                <h1>
                  Revenue:{" "}
                  {Math.round(
                    data.area && data.area * 299 * 4.46
                  ).toLocaleString() + " $"}
                </h1>
                <h1>
                  Bushels:{" "}
                  {Math.round(data.area && data.area * 299).toLocaleString()}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PDFReport;
