import React from "react";

function Menu({ className, ...props }) {
  return (
    <div
      className={`bg-dashboard bg-no-repeat bg-cover bg-right-bottom relative z-0 rounded-2xl ${className}`}
    >
      <div className="bg-black backdrop-saturate-0 bg-opacity-20 h-[100%] w-[100%] absolute pointer-events-none rounded-2xl -z-10"></div>
      <div
        className={`h-[100%] w-[100%] flex items-center justify-start flex-col `}
      >
        {props.children}
      </div>
    </div>
  );
}

export default Menu;
