import React, { useEffect, useRef, useState } from "react";
import { AiOutlineBarChart } from "react-icons/ai";
import Menu from "../../../Menu/Menu";
import Navbar from "../../Components/Navbar/Navbar";
import ValueCircle from "../../../ValueCircle/ValueCircle";
import ClientDashboard from "../../Components/ClientDashboard/ClientDashboard";
import MapContainer from "../../Components/MapContainer/MapContainer";
import generatePDF from "react-to-pdf";
import PDFReport from "../../Components/PDFReport/PDFReport";
import ElevationChart from "../../Components/ElevationChart/ElevationChart";

function DashBoard() {
  const [navbar, setNavbar] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [values, setValues] = useState();
  const [lastOutput, setLastOutput] = useState(0);
  const [lines, setLines] = useState();
  const [zoom, setZoom] = useState(4);
  const [mapPosition, setMapPosition] = useState({ lat: 40, lng: -100 });
  const [hilightedLine, setHighLightedLine] = useState();
  const [inputs, setInputs] = useState({
    nam: "",
    optimal_min_percent: "",
    optimal_max_percent: "",
    bad_min_percent: "",
    bad_max_percent: "",
    peak_percent: "",
    shallow_max_percent: "",
  });

  async function getValues(id = null) {
    let url = `${process.env.REACT_APP_API_URL}/data/outputs/`;
    if (id !== null)
      url = `${process.env.REACT_APP_API_URL}/data/outputs/${id}`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
    };
    fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => {
        setValues(data);
        let last = data.body.length - 1;
        if (id !== null) {
          last = 0;
          data.body = [data.body];
        }
        setLastOutput(last);
      });
  }

  async function getMapData() {
    const url = `${process.env.REACT_APP_API_URL}/data/outputs/${values.body[lastOutput].input}/geolocations/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
    };
    fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => {
        setZoom(15);
        if (data && data.body) {
          // Find the first non-empty geolocation array
          const nonEmptyGeolocation = data.body.find(
            (geolocationArray) => geolocationArray.geolocations.length > 0
          );

          if (
            nonEmptyGeolocation &&
            nonEmptyGeolocation.geolocations &&
            nonEmptyGeolocation.geolocations[0]
          ) {
            setMapPosition({
              lat: nonEmptyGeolocation.geolocations[0][0][0],
              lng: nonEmptyGeolocation.geolocations[0][0][1],
            });
          } else {
            setMapPosition({
              lat: 0,
              lng: 0,
            });
          }
        } else {
          setMapPosition({
            lat: 0,
            lng: 0,
          });
        }

        setLines(data.body);
      });
  }

  useEffect(() => {
    if (
      values?.body[lastOutput]?.id !== undefined &&
      lastOutput !== undefined
    ) {
      getMapData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, lastOutput]);

  const pdfRef = useRef();
  function downloadPDF() {
    generatePDF(pdfRef, {
      filename: inputs["name"] + " Drainage_EFC",
    });
  }

  const valueCircles = [
    {
      title: "Number of Lateral Connections",
      data:
        values?.body[lastOutput]?.lateral_connections !== undefined &&
        values?.body[lastOutput]?.lateral_connections !== null
          ? values?.body[lastOutput]?.lateral_connections
          : "",
      className: "bg-gradient-to-r from-pink-500 to-yellow-500 text-4xl",
    },
    {
      title: `${
        values ? values?.body[lastOutput]?.optimal_boundiries_floor : ""
      } <= Optimal <= ${
        values ? values?.body[lastOutput]?.optimal_boundiries_ceiling : ""
      }`,
      totalDistance:
        values?.body[lastOutput]?.optimal_distance !== undefined &&
        values?.body[lastOutput]?.optimal_distance !== null
          ? values?.body[lastOutput]?.optimal_distance.toFixed(2) + " feet"
          : "",
      data:
        values?.body[lastOutput]?.optimal !== undefined &&
        values?.body[lastOutput]?.optimal !== null
          ? values.body[lastOutput].optimal + "%"
          : "",
      className: "bg-gray-600 text-4xl",
    },
    {
      title: `${
        values ? values?.body[lastOutput]?.good_boundiries_floor_first : ""
      } < Good <= ${
        values ? values?.body[lastOutput]?.good_boundiries_ceiling_first : ""
      }\n And \n${
        values ? values?.body[lastOutput]?.good_boundiries_floor_second : ""
      } < Good <= ${
        values ? values?.body[lastOutput]?.good_boundiries_ceiling_second : ""
      }`,
      totalDistance:
        values?.body[lastOutput]?.good_distance !== undefined &&
        values?.body[lastOutput]?.good_distance !== null
          ? values?.body[lastOutput]?.good_distance.toFixed(2) + " feet"
          : "",
      data:
        values?.body[lastOutput]?.good !== undefined &&
        values?.body[lastOutput]?.good !== null
          ? values.body[lastOutput].good + "%"
          : "",
      className: "bg-green-500 text-4xl",
    },
    {
      title: `${
        values ? values?.body[lastOutput]?.bad_boundiries_floor : ""
      } < Bad <= ${
        values ? values?.body[lastOutput]?.bad_boundiries_ceiling : ""
      }`,
      totalDistance:
        values?.body[lastOutput]?.bad_distance !== undefined &&
        values?.body[lastOutput]?.bad_distance !== null
          ? values?.body[lastOutput]?.bad_distance.toFixed(2) + " feet"
          : "",
      data:
        values?.body[lastOutput]?.bad !== undefined &&
        values?.body[lastOutput]?.bad !== null
          ? values.body[lastOutput].bad + "%"
          : "",
      className: "bg-orange-500 text-4xl",
    },
    {
      title: `${
        values ? values?.body[lastOutput]?.really_bad_boundiries_ceiling : ""
      } < Really Bad `,
      totalDistance:
        values?.body[lastOutput]?.really_bad_distance !== undefined &&
        values?.body[lastOutput]?.really_bad_distance !== null
          ? values?.body[lastOutput]?.really_bad_distance.toFixed(2) + " feet"
          : "",
      data:
        values?.body[lastOutput]?.really_bad !== undefined &&
        values?.body[lastOutput]?.really_bad !== null
          ? values.body[lastOutput].really_bad + "%"
          : "",
      className: "bg-red-500 text-4xl",
    },
    {
      title: `Shallow < ${
        values ? values?.body[lastOutput]?.shallow_boundiries_floor : ""
      }`,
      totalDistance:
        values?.body[lastOutput]?.shallow_distance !== undefined &&
        values?.body[lastOutput]?.shallow_distance !== null
          ? values?.body[lastOutput]?.shallow_distance.toFixed(2) + " feet"
          : "",
      data:
        values?.body[lastOutput]?.shallow !== undefined &&
        values?.body[lastOutput]?.shallow !== null
          ? values.body[lastOutput].shallow + "%"
          : "",
      className: "bg-yellow-500 text-4xl",
    },
    {
      title: "Area",
      data:
        values?.body[lastOutput]?.area !== undefined &&
        values?.body[lastOutput]?.area !== null
          ? values.body[lastOutput].area.toFixed(2)
          : "",
      className: "bg-gradient-to-r from-blue-300 to-yellow-300 text-4xl",
    },
    {
      title: "Map Complexity / 10",
      data:
        values?.body[lastOutput]?.map_complexity !== undefined &&
        values?.body[lastOutput]?.map_complexity !== null
          ? values.body[lastOutput].map_complexity
          : "",
      className: "bg-gradient-to-r from-green-300 to-red-300 text-4xl",
    },
  ];

  return (
    <div className="w-[100%] h-[90%] flex items-center justify-start flex-col laptop:mt-8">
      <div>
        <div
          onClick={(e) => {
            if (e.target.id !== "button") return;
            if (dashboard) {
              setDashboard(false);
              setNavbar(false);
            } else {
              setDashboard(true);
              setNavbar(false);
            }
          }}
        >
          <ClientDashboard
            isOpen={dashboard}
            getValues={getValues}
            lines={lines}
            setHilightedLine={setHighLightedLine}
            hilightedLine={hilightedLine}
            setInputs={setInputs}
            PDF={() => {
              if (values && lastOutput !== undefined) return downloadPDF;
              else return false;
            }}
          />
        </div>
      </div>
      <div className="flex items-center justify-end w-[90%]">
        <div
          onClick={(e) => {
            if (e.target.id !== "button") return;
            if (navbar) {
              setDashboard(false);
              setNavbar(false);
            } else {
              setDashboard(false);
              setNavbar(true);
            }
          }}
        >
          <Navbar isOpen={navbar} />
        </div>
      </div>
      <div className="w-[100%] flex items-center justify-center mt-8">
        <div className="w-[90%] flex items-center justify-between flex-col">
          <div className="h-[300px] w-[95%] mb-8">
            <div className="flex items-center laptop:justify-start justify-center mb-2">
              <AiOutlineBarChart className="bg-black text-white w-[24px] h-[24px] p-[5px] rounded-full" />
              <h1 className="ml-2">Efficiency Calculator</h1>
            </div>
            <Menu className="rounded-2xl h-[90%]">
              <div className="w-[100%] h-[100%] flex items-center justify-evenly flex-row overflow-x-auto">
                {valueCircles.map((circle, index) => (
                  <ValueCircle
                    key={index}
                    title={circle.title}
                    data={circle.data}
                    totalDistance={circle.totalDistance}
                    className={circle.className}
                  />
                ))}
              </div>
            </Menu>
          </div>
          <div className="h-[100%] w-[95%]">
            <div className="flex items-center laptop:justify-start justify-center mb-2">
              <AiOutlineBarChart className="bg-black text-white w-[24px] h-[24px] p-[5px] rounded-full" />
              <h1 className="ml-2">
                {inputs["name"] && values ? inputs["name"] : "Map"}
              </h1>
            </div>
            <Menu className="rounded-2xl w-[100%] h-[500px] flex items-center justify-center mb-4">
              <MapContainer
                lines={lines}
                zoom={zoom}
                mapPosition={mapPosition}
                hilightedLine={hilightedLine}
              />
            </Menu>
            <Menu className="rounded-2xl w-[100%] h-[500px] flex items-center justify-center mb-4">
              <ElevationChart
                highlightedLine={lines && lines[hilightedLine]?.line_name}
                output={values?.body[lastOutput]?.input}
              />
            </Menu>
          </div>
        </div>
      </div>
      {values && (
        <PDFReport
          ref={pdfRef}
          data={values.body[lastOutput]}
          mapName={inputs["name"]}
        />
      )}
    </div>
  );
}

export default DashBoard;
