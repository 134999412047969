import React, { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { NOCHOSENLINE } from "../../../../constants";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ElevationChart = ({ highlightedLine, output }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    // Fetch data whenever highlightedLine or output changes
    if (highlightedLine && highlightedLine !== NOCHOSENLINE && output) {
      const fetchData = async () => {
        try {
          const url = `${process.env.REACT_APP_API_URL}/data/outputs/${output}/line_curve/?line=${highlightedLine}`;
          const requestOptions = {
            method: "GET",
            headers: {
              Authorization: "JWT " + localStorage.getItem("accessToken"),
            },
          };
          const response = await fetch(url, requestOptions);
          const data = await response.json();
          setChartData({
            labels: data.distances.map((distance) => distance.toFixed(3)), // Format x-axis values to 3 decimal places
            datasets: [
              {
                label: "Surface Elevations",
                data: data.serface_elevations,
                borderColor: "rgba(0, 0, 0, 1)", // black
                fill: false,
              },
              {
                label: "Minimum Elevation",
                data: data.minimum_depth_elevations,
                borderColor: "rgba(255, 0, 0, 1)", // Red color
                fill: false,
              },
              {
                label: "Maximum Elevation",
                data: data.miximum_depth_elevations,
                borderColor: "rgba(0, 0, 255, 1)", // Blue color
                fill: false,
              },
              {
                label: "Optimal Depth Elevations",
                data: data.optimal_depth_elevations,
                borderColor: "rgba(255, 206, 86, 1)", // Yellow color
                fill: false,
              },
              {
                label: "Elevation Generated",
                data: data.new_values,
                borderColor: "rgba(0, 128, 0, 1)", // Green color
                fill: false,
              },
            ],
          });
        } catch (error) {
          console.error("Error fetching data:", error);
          setChartData(null); // Clear the chart data on error
        }
      };

      fetchData();
    } else {
      // Clear the chart data if no highlighted line is selected
      setChartData(null);
    }
  }, [highlightedLine, output]);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Disable the default aspect ratio
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: "black", // Set legend text color to black
        },
      },
      title: {
        display: true,
        text: "Elevation vs. Distance",
        color: "black", // Set chart title color to black
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Distance",
          color: "black", // Set x-axis title color to black
        },
        ticks: {
          color: "black", // Set x-axis tick color to black
          maxTicksLimit: 5, // Limit the number of x-axis tick marks to 5
        },
      },
      y: {
        title: {
          display: true,
          text: "Elevation",
          color: "black", // Set y-axis title color to black
        },
        ticks: {
          color: "black", // Set y-axis tick color to black
        },
      },
    },
  };

  return (
    <div className="w-full h-full p-4 bg-white rounded-lg shadow-md">
      {chartData ? (
        <Line data={chartData} options={options} />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <p className="text-black">No data available. Please select a line.</p>
        </div>
      )}
    </div>
  );
};

export default ElevationChart;
