import React from "react";
import Menu from "../../../Menu/Menu";
import ValueCircle from "../../../ValueCircle/ValueCircle";
import AdminNavigation from "../../Components/AdminNavigation/AdminNavigation";
import { useState, useEffect } from "react";
import { json2csv } from "json-2-csv";
import { CSVLink } from "react-csv";
function AdminDashboard() {
  const [dashboardData, setDashboardData] = useState({});
  const [csvData, setCsvData] = useState();
  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/admin/dashboard`;
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setDashboardData(data);
      });
  }, []);

  async function handleDownlodCSV() {
    let url = `${process.env.REACT_APP_API_URL}/data/outputs/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
    };
    fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => {
        let organisedBody = [];
        for (let i = 0; i < data.body.length; i++) {
          console.log(data.body[i]);
          let e = data.body[i];
          let row = {};
          row.id = e.id;
          row.lateral_connections = e.lateral_connections;
          row.optimal = e.optimal;
          row.good = e.good;
          row.bad = e.bad;
          row.really_bad = e.really_bad;
          row.shallow = e.shallow;
          row.area = e.area;
          row.map_complexity = e.map_complexity;
          row.input = e.input;
          row.optimal_boundiries_floor = e.optimal_boundiries_floor;
          row.optimal_boundiries_ceiling = e.optimal_boundiries_ceiling;
          row.good_boundiries_floor_first = e.good_boundiries_floor_first;
          row.good_boundiries_ceiling_first = e.good_boundiries_ceiling_first;
          row.good_boundiries_floor_second = e.good_boundiries_floor_second;
          row.good_boundiries_ceiling_second = e.good_boundiries_ceiling_second;
          row.bad_boundiries_ceiling = e.bad_boundiries_ceiling;
          row.bad_boundiries_floor = e.bad_boundiries_floor;
          row.really_bad_boundiries_ceiling = e.really_bad_boundiries_ceiling;
          row.shallow_boundiries_floor = e.shallow_boundiries_floor;
          organisedBody.push(row);
        }
        return setCsvData(json2csv(organisedBody, { prependHeader: false }));
      });
  }

  useEffect(() => {
    handleDownlodCSV();
  }, []);

  return (
    <div className="w-[100%] h-[90%] flex items-center justify-start flex-col laptop:mt-8">
      <AdminNavigation />

      <div className="h-[100%] w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-bold text-3xl mb-8">Dashboard</h1>
        <div className="flex justify-center items-center flex-col mr-[2%] ml-[2%] w-[100%] h-[100%]">
          <div className="w-[90%] flex items-center justify-between flex-col h-[100%]">
            <div className="h-[100%] w-[95%]">
              <Menu className="rounded-2xl h-[90%]">
                <div className="w-[100%] h-[100%] flex items-center justify-evenly flex-row overflow-x-auto  flex-wrap">
                  <ValueCircle
                    title="Current Users"
                    data={dashboardData.total_users}
                    className="min-w-[280px] min-h-[280px] bg-gradient-to-b from-[#1252FF] to-[#AAFFB2] justify-evenly font-bold p-[50px]"
                  />
                  <ValueCircle
                    title="Total Area"
                    data={parseFloat(dashboardData.total_area).toFixed(2)}
                    className="min-w-[280px] min-h-[280px] bg-gradient-to-r from-slate-500 to-slate-800 justify-evenly font-bold p-[50px]"
                  />
                  <ValueCircle
                    title="Total Revenue"
                    data="0$"
                    className="min-w-[280px] min-h-[280px] bg-gradient-to-b from-[#FFB1F7] to-[#F2A8B1] justify-evenly font-bold p-[50px]"
                  />
                  {csvData && (
                    <div className="bg-green-500 p-[8px] rounded-[5px] text-white absolute right-[20px] top-[20px] cursor-pointer">
                      <CSVLink
                        data={csvData}
                        headers={[
                          "Id",
                          "Number Of Lateral Connections",
                          "Optimal",
                          "Good",
                          "Bad",
                          "Really Bad",
                          "Shallow",
                          "Area In Acres",
                          "Map Complexity",
                          "Input",
                          "Optimal Boundiries Floor",
                          "Optimal Boundiries Ceiling",
                          "Good Boundiries Floor First",
                          "Good Boundiries Ceiling First",
                          "Good Boundiries Floor Second",
                          "Good Boundiries Ceiling Second",
                          "Bad Boundiries Ceiling",
                          "Bad Boundiries Floor",
                          "Really Bad Boundiries Ceiling",
                          "Shallow Boundiries Floor",
                        ]}
                        filename={"map-data.csv"}
                      >
                        Download Maps Data
                      </CSVLink>
                    </div>
                  )}
                </div>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
