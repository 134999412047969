import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AuthProvider } from "./Components/AuthContext";
import DashBoard from "./Components/ClientPanel/Pages/DashBoard/DashBoard";
import SignIn from "./Components/SignIn/SignIn";
import Background from "./Components/Background/Background";
import KnowledgeBase from "./Components/ClientPanel/Pages/KnowledgeBase/KnowledgeBase";
import AboutUs from "./Components/ClientPanel/Pages/AboutUs/AboutUs";
import ContactUs from "./Components/ClientPanel/Pages/ContactUs/ContactUs";
import Subscription from "./Components/ClientPanel/Pages/Subscription/Subscription";
import AdminDashboard from "./Components/AdminPanel/Pages/AdminDashboard/AdminDashboard";
import ClientData from "./Components/AdminPanel/Pages/ClientData/ClientData";
import ClientSubscriptions from "./Components/AdminPanel/Pages/ClientSubscriptions/ClientSubscriptions";
import AdminNotifications from "./Components/AdminPanel/Pages/AdminNotifcations/AdminNotifications";
import CreateUser from "./Components/AdminPanel/Pages/CreateUser/CreateUser";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Background />,
    children: [
      { path: "", element: <SignIn /> },
      { path: "Dashboard", element: <DashBoard /> },
      { path: "KnowledgeBase", element: <KnowledgeBase /> },
      { path: "AboutUs", element: <AboutUs /> },
      { path: "ContactUs", element: <ContactUs /> },
      { path: "Subscription", element: <Subscription /> },
    ],
  },
  {
    path: "/Admin",
    element: <Background adminPanel />,
    children: [
      { path: "", element: <AdminDashboard /> },
      { path: "ClientData", element: <ClientData /> },
      { path: "Subscriptions", element: <ClientSubscriptions /> },
      { path: "Notifications", element: <AdminNotifications /> },
      { path: "CreateUser", element: <CreateUser /> },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
