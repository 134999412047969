import React from "react";
import { AiOutlineBell } from "react-icons/ai";
function SubscribedClient({
  name,
  plan,
  img,
  lastPayed,
  nextPayment,
  className,
  notification,
}) {
  return (
    <div
      className={`flex justify-evenly items-center w-[1000px] text-center h-[100%] ${className}`}
    >
      {img !== undefined ? (
        <div className="border-2 border-black w-[42px] h-[42px] rounded-full"></div>
      ) : (
        <div className="w-[42px] h-[42px] rounded-full"></div>
      )}
      <div
        className={`flex justify-evenly items-center w-[1000px] text-center h-[100%] bg-white`}
      >
        <p className="w-[20%]">{name}</p>
        <p className="w-[20%]">{plan}</p>
        <p className="w-[20%]">{lastPayed}</p>
        <p className="w-[20%]">{nextPayment}</p>
      </div>
      {notification !== undefined ? (
        <div className="border-2 border-black w-[42px] h-[42px] rounded-full flex justify-center items-center">
          <AiOutlineBell className="w-[24px] h-[24px]" />
        </div>
      ) : (
        <div className="w-[42px] h-[42px] rounded-full"></div>
      )}
    </div>
  );
}

export default SubscribedClient;
