import React from "react";

function Video({ title, thumbnal, description, className }) {
  return (
    <div
      className={`flex justify-center items-center flex-col m-2 ${className}`}
    >
      <h1 className="text-xl font-bold">{title}</h1>
      <div className="h-[100%] w-[100%] border-black border-8 rounded-3xl"></div>
      <h1 className="w-[350px] text-center">{description}</h1>
    </div>
  );
}

export default Video;
