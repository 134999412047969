import React, { useState, useEffect, useContext } from "react";
import TextInput from "../TextInput/TextInput";
import Button from "../Button/Button";
import Menu from "../Menu/Menu";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { AuthContext } from "../AuthContext";
import {
  AiOutlineUser,
  AiOutlineLock,
  AiOutlineEyeInvisible,
} from "react-icons/ai";

function SignIn() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginData, setLoginData] = useState(null);
  const { refreshAuthState } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (loginData?.status === 200) {
      const token = loginData.body.access;
      const decoded = jwtDecode(token);

      localStorage.setItem("accessToken", loginData.body.access);
      localStorage.setItem("refreshToken", loginData.body.refresh);
      localStorage.setItem("Admin", decoded.Admin);

      refreshAuthState().then(() => {
        if (decoded.Admin) {
          navigate("/admin");
        } else {
          navigate("/dashboard");
        }
      });
    }
    // eslint-disable-next-line
  }, [loginData, navigate]);

  const login = async () => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/auth/jwt/create/`;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      };
      const response = await fetch(url, requestOptions);
      const data = await response.json();
      setLoginData({ status: response.status, body: data });
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <div className="flex items-center justify-center h-screen w-screen">
      <Menu className="h-[430px] rounded-2xl w-[350px]">
        <h1 className="text-white text-2xl mt-5 mb-10 z-20">FieldFlow360</h1>
        <div className="h-[70%] w-[70%] grid grid-cols-3 gap-4 z-20">
          <h1 className="text-white text-xl col-span-3 text-center">
            User Login
          </h1>
          <TextInput
            className="flex-col h-5 col-span-3"
            label="Username"
            placeholder=" example"
            iconLeft={() => (
              <div className="w-[30px] h-[30px] flex items-end justify-center">
                <AiOutlineUser className="w-[26px] h-[26px]" />
              </div>
            )}
            onChange={(e) => setUsername(e.target.value)}
            errorMsg={loginData?.body?.username}
          />
          <TextInput
            className="flex-col col-span-3 h-5"
            label="Password"
            placeholder=" password123!@#"
            isSensitive={true}
            iconLeft={() => (
              <div className="w-[30px] h-[30px] flex items-center justify-center">
                <AiOutlineLock className="w-[24px] h-[24px]" />
              </div>
            )}
            iconRight={() => (
              <AiOutlineEyeInvisible className="w-[16px] h-[16px]" />
            )}
            onChange={(e) => setPassword(e.target.value)}
            errorMsg={loginData?.body?.password}
          />
          {loginData?.body?.detail && (
            <div className="flex-col col-span-3">
              <h1 className="mt-2 w-[100%] bg-red-500 text-white pl-[4px] text-center rounded p-2">
                {loginData.body.detail}
              </h1>
            </div>
          )}
          <Button
            onClick={login}
            className="col-span-3 flex items-center justify-center"
            buttonClass="border-white border-2 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-50 text-white w-[150px]"
            text="Sign In"
          />
        </div>
      </Menu>
    </div>
  );
}

export default SignIn;
