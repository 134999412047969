import React from "react";

function ClientNotification({ name, plan, img, due, icon1, icon2, className }) {
  return (
    <div
      className={`flex justify-evenly items-center w-[1000px] text-center h-[100%] ${className}`}
    >
      {img !== undefined ? (
        <div className="border-2 border-black w-[42px] h-[42px] rounded-full"></div>
      ) : (
        <div className="w-[42px] h-[42px] rounded-full"></div>
      )}
      <div className="flex justify-evenly items-center w-[1000px] text-center h-[100%] bg-white">
        <p className="w-[20%]">{name}</p>
        <p className="w-[20%]">{plan}</p>
        <p className="w-[20%]">{due}</p>
        <div className="flex justify-evenly itmes-center w-[30%]">
          <div className="w-[24px] h-[24px] w-[50%] flex justify-center items-center">
            {icon1 !== undefined && icon1()}
          </div>
          <div className="w-[24px] h-[24px] w-[50%] flex justify-center items-center">
            {icon2 !== undefined && icon2()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientNotification;
