import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import TextInput from "../../../TextInput/TextInput";
import SubscribedClient from "../../Components/SubscribedClient/SubscribedClient";
import AdminNavigation from "../../Components/AdminNavigation/AdminNavigation";

function ClientSubscriptions() {
  return (
    <div className="w-[100%] h-[90%] flex items-center justify-start flex-col laptop:mt-8">
      <AdminNavigation />
      <div className="h-[100%] w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-bold text-3xl mb-8">Subscriptions</h1>
        <TextInput placeholder="search" iconRight={() => <AiOutlineSearch />} />
        <div className="flex items-center justify-center w-[100%]">
          <div className="flex justify-start items-start flex-col mr-[2%] ml-[2%]  h-[100%] mt-8 overflow-x-auto overflow-y-auto">
            <SubscribedClient
              name="Username"
              plan="Subscription Plan"
              lastPayed="Last Paid"
              nextPayment="Next Payment"
              className={"mb-8 h-[50px]"}
            />
            <SubscribedClient
              name="Floyd Dixon"
              plan="200$ Plan"
              lastPayed="24/9/2024"
              nextPayment="24/10/2024"
              img={true}
              notification={true}
              className={"mb-6 h-[30px]"}
            />
            <SubscribedClient
              name="Floyd Dixon"
              plan="200$ Plan"
              lastPayed="24/9/2024"
              nextPayment="24/10/2024"
              img={true}
              notification={true}
              className={"mb-6 h-[30px]"}
            />
            <SubscribedClient
              name="Floyd Dixon"
              plan="200$ Plan"
              lastPayed="24/9/2024"
              nextPayment="24/10/2024"
              img={true}
              notification={true}
              className={"mb-6 h-[30px]"}
            />
            <SubscribedClient
              name="Floyd Dixon"
              plan="200$ Plan"
              lastPayed="24/9/2024"
              nextPayment="24/10/2024"
              img={true}
              notification={true}
              className={"mb-6 h-[30px]"}
            />
            <SubscribedClient
              name="Floyd Dixon"
              plan="200$ Plan"
              lastPayed="24/9/2024"
              nextPayment="24/10/2024"
              img={true}
              notification={true}
              className={"mb-6 h-[30px]"}
            />
            <SubscribedClient
              name="Floyd Dixon"
              plan="200$ Plan"
              lastPayed="24/9/2024"
              nextPayment="24/10/2024"
              img={true}
              notification={true}
              className={"mb-6 h-[30px]"}
            />
            <SubscribedClient
              name="Floyd Dixon"
              plan="200$ Plan"
              lastPayed="24/9/2024"
              nextPayment="24/10/2024"
              img={true}
              notification={true}
              className={"mb-6 h-[30px]"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientSubscriptions;
