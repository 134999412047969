import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import SubscriptionCard from "../../Components/SubscriptionCard/SubscriptionCard";

function Subscription() {
  return (
    <div className="w-[100%] h-[100%] flex items-center justify-start flex-col mt-8 ">
      <div className="flex items-center justify-end w-[90%]">
        <Navbar />
      </div>
      <div className="w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-semibold text-3xl mb-[100px]">Subscription</h1>
        <div className="w-[100%] h-[100%] flex items-center justify-evenly flex-row flex-wrap mb-8">
          <SubscriptionCard
            key={0}
            className="m-4"
            label="Once"
            price="100"
            use="For one use"
            descriptionTitle="description"
            color="text-green-500"
            bannerColor="bg-green-900"
            description=""
            benefits={[
              { active: true, benefit: "" },
              { active: false, benefit: "" },
              { active: false, benefit: "" },
              { active: false, benefit: "" },
            ]}
          />
          <SubscriptionCard
            key={1}
            className="m-4"
            label="10 Maps"
            price="250"
            use="For 10 Uses"
            descriptionTitle="description"
            color="text-blue-500"
            bannerColor="bg-blue-900"
            description=""
            benefits={[
              { active: true, benefit: "" },
              { active: true, benefit: "" },
              { active: true, benefit: "" },
              { active: false, benefit: "" },
            ]}
          />
          <SubscriptionCard
            key={3}
            className="m-4"
            label="25 Maps"
            price="500"
            use="For 25 Uses"
            descriptionTitle="description"
            color="text-red-500"
            bannerColor="bg-red-900"
            description=""
            benefits={[
              { active: true, benefit: "" },
              { active: true, benefit: "" },
              { active: true, benefit: "" },
              { active: true, benefit: "" },
            ]}
          />
          <SubscriptionCard
            key={3}
            className="m-4"
            label="Unlimited Maps"
            price="1000"
            use="Unlimited"
            descriptionTitle="description"
            color="text-purple-500"
            bannerColor="bg-purple-900"
            description=""
            benefits={[
              { active: true, benefit: "" },
              { active: true, benefit: "" },
              { active: true, benefit: "" },
              { active: true, benefit: "" },
            ]}
          />
        </div>
      </div>
    </div>
  );
}

export default Subscription;
