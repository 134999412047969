import React from "react";

function ValueCircle({ title, data, className, totalDistance, textClassName }) {
  const renderTitleWithLineBreaks = title
    ? title.split("\n").map((line, index) => (
        <span key={index}>
          {line}
          <br />
        </span>
      ))
    : null;

  return (
    <div
      className={`relative m-2 min-w-[200px] w-[200px] h-[200px] rounded-full flex justify-center items-center flex-col text-center shadow-lg overflow-hidden ${className}`}
      style={{
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className={`bg-white w-[180px] h-[180px] rounded-full flex justify-center items-center flex-col ${textClassName}`}
      >
        <h1 className="font-bold text-gray-800">{data}</h1>
        <h2 className="text-[15px] text-gray-600 font-bold">{totalDistance}</h2>
        <p className="text-[13px] text-gray-500 mt-2 font-bold leading-tight">
          {renderTitleWithLineBreaks}
        </p>
      </div>
    </div>
  );
}

export default ValueCircle;
