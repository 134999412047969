import React, { useContext } from "react";
import { useState } from "react";
import Menu from "../../../Menu/Menu";
import Button from "../../../Button/Button";
import {
  AiOutlineDatabase,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiTwotoneDashboard,
  AiOutlineUserAdd,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";
function AdminNavigation() {
  const [dashboard, setDashboard] = useState(false);
  const { logout } = useContext(AuthContext);

  return (
    <>
      {dashboard ? (
        <div className="absolute left-[20px] z-40 top-0 h-screen flex items-center justify-center">
          <div className="laptop:w-[42px] w-[32px] absolute top-0 laptop:right-[-18px] right-[-13px] z-30 h-[100%] flex justify-end items-center">
            <AiOutlineMenuFold
              className="block z-30 bg-black text-white p-[8px] laptop:w-[42px] laptop:h-[42px] w-[32px] h-[32px]  rounded-full"
              onClick={() => setDashboard(false)}
            />
          </div>
          <Menu className="rounded-2xl min-w-[300px] w-[300px] text-white h-[95%]">
            <div className="w-[100%] h-[100%] flex justify-start items-center flex-col z-20 overflow-x-hidden">
              <h1 className="text-white text-2xl mt-5 mb-10">FieldFlow360</h1>
              <div className="w-[100%] min-h-[20%] m-1 flex align-center justify-between flex-col text-black mb-5">
                <Link to="/admin/">
                  <Button
                    text="Dashboard"
                    icon={() => <AiTwotoneDashboard />}
                  />
                </Link>
                <Link to="/admin/clientData/">
                  <Button text="User Data" icon={() => <AiOutlineDatabase />} />
                </Link>
                <Link to="/admin/createUser/">
                  <Button
                    text="Create User"
                    icon={() => <AiOutlineUserAdd />}
                  />
                </Link>
              </div>
              <div className="w-[100%] flex items-end justify-center h-[100%] m-[20px]">
                <Link
                  to="/"
                  className="bg-red-500 p-[8px] rounded-[5px] text-white"
                  onClick={logout}
                >
                  Logout
                </Link>
              </div>
            </div>
          </Menu>
        </div>
      ) : (
        <div className="w-[48px] h-[48px] absolute top-0 left-[12px] z-30 flex justify-start items-center h-screen">
          <AiOutlineMenuUnfold
            className="block z-30 bg-black text-white p-[8px] laptop:w-[42px] laptop:h-[42px] w-[32px] h-[32px] rounded-full"
            onClick={() => setDashboard(true)}
          />
        </div>
      )}
    </>
  );
}

export default AdminNavigation;
