import React, { useContext } from "react";
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from "react-icons/ai";
import { useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../AuthContext";

function Navbar({ isOpen }) {
  const [menu, setMenu] = useState(false);
  const { logout } = useContext(AuthContext);

  function menuState() {
    if (isOpen !== undefined) {
      if (isOpen) {
        return (
          <>
            <div
              id="button"
              className="laptop:hidden w-[32px] absolute top-0 right-[184px] z-30 h-[100%] flex justify-end items-center"
            >
              <AiOutlineMenuUnfold
                id="button"
                className="laptop:hidden block z-30 bg-white p-[8px] w-[32px] h-[32px] rounded-full"
                onClick={() => setMenu(false)}
              />
            </div>
            <div className="flex font-semibold laptop:hidden w-[200px] flex-col h-screen absolute top-0 bg-white justify-center items-center right-0 z-20">
              <div className="flex justify-evenly items-center flex-col h-[70%]">
                <Link to="/dashboard">Dashboard</Link>
                <Link to="/aboutus">About Us</Link>
                <Link to="/subscription">Subscription</Link>
                <Link to="/contactus">Contact Us</Link>
                <Link to="/knowledgebase">Knowledge Base</Link>
                <Link
                  to="/"
                  className="bg-red-500 p-[8px] rounded-[5px] text-white"
                  onClick={logout}
                >
                  Logout
                </Link>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <div
            id="button"
            className="laptop:hidden w-[48px] h-[48px] absolute top-0 right-[12px] z-30 flex justify-end items-center h-screen"
          >
            <AiOutlineMenuFold
              id="button"
              className="block z-30 bg-white text-black p-[8px] w-[32px] h-[32px] rounded-full"
              onClick={() => setMenu(true)}
            />
          </div>
        );
      }
    }
    if (menu) {
      return (
        <>
          <div
            id="button"
            className="laptop:hidden w-[32px] absolute top-0 right-[184px] z-30 h-[100%] flex justify-end items-center"
          >
            <AiOutlineMenuUnfold
              id="button"
              className="laptop:hidden block z-30 bg-white p-[8px] w-[32px] h-[32px] rounded-full"
              onClick={() => setMenu(false)}
            />
          </div>
          <div className="flex font-semibold laptop:hidden w-[200px] flex-col h-screen absolute top-0 bg-white justify-center items-center right-0 z-20">
            <div className="flex justify-evenly items-center flex-col h-[70%]">
              <Link to="/dashboard">Dashboard</Link>
              <Link to="/aboutus">About Us</Link>
              <Link to="/subscription">Subscription</Link>
              <Link to="/contactus">Contact Us</Link>
              <Link to="/knowledgebase">Knowledge Base</Link>
              <Link
                to="/"
                className="bg-red-500 p-[8px] rounded-[5px] text-white"
                onClick={logout}
              >
                Logout
              </Link>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          id="button"
          className="laptop:hidden w-[48px] h-[48px] absolute top-0 right-[12px] z-30 flex justify-end items-center h-screen"
        >
          <AiOutlineMenuFold
            id="button"
            className="block z-30 bg-white text-black p-[8px] w-[32px] h-[32px] rounded-full"
            onClick={() => setMenu(true)}
          />
        </div>
      );
    }
  }

  return (
    <>
      {menuState()}
      <div className="laptop:w-[700px] laptop:flex laptop:items-center laptop:justify-between font-semibold laptop:flex-row laptop:h-[100%] laptop:relative laptop:bg-transparent laptop:p-0 laptop:block hidden">
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/aboutus">About Us</Link>
        <Link to="/subscription">Subscription</Link>
        <Link to="/contactus">Contact Us</Link>
        <Link to="/knowledgebase">Knowledge Base</Link>
        <Link
          to="/"
          className="bg-red-500 p-[8px] rounded-[5px] text-white"
          onClick={logout}
        >
          Logout
        </Link>
      </div>
    </>
  );
}

export default Navbar;
