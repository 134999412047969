import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkTokens();
    // eslint-disable-next-line
  }, []);

  const scheduleTokenRefresh = (token) => {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    const expiresIn = decoded.exp - currentTime;
    const timeout = (expiresIn - 60) * 1000; // Refresh 1 minute before expiration

    setTimeout(async () => {
      const refreshData = await refreshToken();
      if (refreshData.status === 200) {
        localStorage.setItem("accessToken", refreshData.body.access);
        setIsAuthenticated(true);
        setIsAdmin(localStorage.getItem("Admin") === "true");
        scheduleTokenRefresh(refreshData.body.access); // Schedule next refresh
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    }, timeout);
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      scheduleTokenRefresh(accessToken);
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const checkTokens = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      setIsAuthenticated(false);
      setIsAdmin(false);
      return;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    if (decoded.exp < currentTime) {
      const refreshData = await refreshToken();
      if (refreshData.status === 200) {
        localStorage.setItem("accessToken", refreshData.body.access);
        setIsAuthenticated(true);
        setIsAdmin(localStorage.getItem("Admin") === "true");
        scheduleTokenRefresh(refreshData.body.access); // Schedule next refresh
      } else {
        setIsAuthenticated(false);
        setIsAdmin(false);
      }
    } else {
      setIsAuthenticated(true);
      setIsAdmin(localStorage.getItem("Admin") === "true");
      scheduleTokenRefresh(accessToken); // Schedule next refresh
    }
  };

  const refreshToken = async () => {
    if (localStorage.getItem("refreshToken") === undefined) {
      return 400;
    }
    const url = `${process.env.REACT_APP_API_URL}/auth/jwt/refresh`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        refresh: localStorage.getItem("refreshToken"),
      }),
    };
    return await fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => data);
  };

  const refreshAuthState = async () => {
    await checkTokens();
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("Admin");
    setIsAuthenticated(false);
    setIsAdmin(false);
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, isAdmin, refreshAuthState, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
