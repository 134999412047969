import React, { useEffect, useState } from "react";
import {
  AiOutlineEdit,
  AiOutlineUserDelete,
  AiOutlineSearch,
} from "react-icons/ai";
import TextInput from "../../../TextInput/TextInput";
import Client from "../../Components/Client/Client";
import AdminNavigation from "../../Components/AdminNavigation/AdminNavigation";

function ClientData() {
  const [users, setUsers] = useState();
  const [userType, setUserType] = useState("clients");
  const [admins, setAdmins] = useState();
  const [clients, setClients] = useState();
  const [searchTerm, setSearchTerm] = useState();

  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  function handleUserType(e) {
    setUserType(e.target.value);
  }

  async function getUsersByUrl(url) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
    };
    return await fetch(url, requestOptions).then((response) =>
      response.json().then((data) => ({ status: response.status, body: data }))
    );
  }

  async function getUsers() {
    const url = `${process.env.REACT_APP_API_URL}/auth/users/`;
    getUsersByUrl(url).then((data) => setUsers(data));
  }

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (users) {
      const adminsUrl = `${process.env.REACT_APP_API_URL}/data/usermenu/?type=admin`;
      getUsersByUrl(adminsUrl).then((data) =>
        setAdmins(data.body.map((e) => e.username))
      );
      const clientsUrl = `${process.env.REACT_APP_API_URL}/data/usermenu/?type=client`;
      getUsersByUrl(clientsUrl).then((data) =>
        setClients(data.body.map((e) => e.username))
      );
    }
  }, [users]);

  return (
    <div className="w-[100%] h-[90%] flex items-center justify-start flex-col laptop:mt-8">
      <AdminNavigation />
      <div className="h-[100%] w-[100%] flex items-center flex-col mt-8">
        <div className="h-[150px] flex items-center justify-center flex-col">
          <h1 className="font-bold text-3xl mb-8">User Data</h1>
          <TextInput
            onChange={handleSearch}
            placeholder="search"
            iconRight={() => <AiOutlineSearch />}
          />
          <select
            className="w-[100%] "
            onChange={(e) => {
              handleUserType(e);
            }}
          >
            <option value={"clients"}>Clients</option>
            <option value={"admins"}>Admins</option>
          </select>
        </div>
        <div className="flex items-center justify-center w-[100%]">
          <div className="flex justify-start items-start flex-col mr-[2%] ml-[2%] h-[100%] mt-8 overflow-x-auto overflow-y-auto">
            <Client
              name="Username"
              email="Email"
              plan="Subscription Plan"
              icon1={() => <p className="w-[24px] h-[24px] w-[50%]">Edit</p>}
              icon2={() => <p className="w-[24px] h-[24px] w-[50%]">Remove</p>}
              className={"mb-8 h-[50px]"}
            />

            {users !== undefined &&
              users.body.map((e, key) => {
                if (
                  searchTerm &&
                  searchTerm !== "" &&
                  !e.username.includes(searchTerm)
                )
                  return <></>;
                if (
                  (userType === "clients" && clients?.includes(e.username)) ||
                  (userType === "admins" && admins?.includes(e.username))
                ) {
                  return (
                    <Client
                      key={key}
                      id={e.id}
                      name={e.username}
                      email={e.email}
                      plan="200$"
                      editable={true}
                      img={true}
                      icon1={() => (
                        <AiOutlineEdit className="w-[24px] h-[24px]" />
                      )}
                      icon2={() => (
                        <AiOutlineUserDelete className="w-[24px] h-[24px]" />
                      )}
                      className={"mb-6 h-[30px]"}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClientData;
