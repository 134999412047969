import React from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import Menu from "../../../Menu/Menu";

function SubscriptionCard({
  className,
  label,
  price,
  use,
  descriptionTitle,
  description,
  benefits,
  color,
  bannerColor,
}) {
  function renderBenefits() {
    return benefits.map((data, key) => (
      <div className="flex justify-center items-center" key={key}>
        {data.active ? (
          <AiOutlineCheckCircle
            className={`mr-[10px] h-[20px] w-[20px] ${color}`}
          />
        ) : (
          <AiOutlineCheckCircle className="mr-[10px] h-[20px] w-[20px]" />
        )}
        <p>{data.benefit}</p>
      </div>
    ));
  }
  return (
    <Menu className={`rounded-xl h-[550px] w-[380px] ${className}`}>
      <div className="flex justify-center items-start  h-[100%] w-[100%] text-white ">
        <div className="flex justify-between items-center flex-col h-[100%] w-[100%]">
          <h1
            className={`text-center flex justify-center items-center w-[100%] z-100 h-[50px] text-2xl rounded-tl-xl rounded-tr-xl text-2xl ${bannerColor}`}
          >
            {label}
          </h1>
          <div className="h-[80%] w[100%] flex justify-between items-center flex-col pb-[80px]">
            <div className="flex justify-between items-center w-[170px]">
              <h1 className="text-2xl">{price}$</h1>
              <p>{use}</p>
            </div>
            <div className="bg-gray-500 bg-opacity-40 h-[110px] p-[10px] rounded-2xl w-[300px]">
              <p>{descriptionTitle}</p>
              <p>{description}</p>
            </div>
            <div className="flex justify-between items-start flex-col h-[130px] w-[300px]">
              {renderBenefits()}
            </div>
          </div>
        </div>
      </div>
    </Menu>
  );
}

export default SubscriptionCard;
