import React, { useState } from "react";

function Client({
  id,
  name,
  email,
  plan,
  img,
  editable,
  icon1,
  icon2,
  className,
}) {
  const [editedEmail, setEditedEmail] = useState(email);
  const [clientDeleted, setClientDeleted] = useState(false);

  async function deleteClient() {
    const url = `${process.env.REACT_APP_API_URL}/auth/users/${id}/`;
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
    };
    fetch(url, requestOptions).then(() => {
      setClientDeleted(true);
    });
  }
  async function editClient() {
    console.log(editedEmail);
    const url = `${process.env.REACT_APP_API_URL}/auth/users/${id}/`;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({ email: editedEmail }),
    };
    fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => {
        if (data.status === 400) {
          alert("invalid change");
          setEditedEmail(email);
        }
      });
  }
  if (!clientDeleted)
    return (
      <div
        className={`flex justify-evenly items-center w-[1000px] text-center h-[100%] ${className}`}
      >
        {img !== undefined ? (
          <div className="border-2 border-black w-[42px] h-[42px] rounded-full"></div>
        ) : (
          <div className="w-[42px] h-[42px] rounded-full"></div>
        )}
        <div className="flex justify-evenly items-center w-[1000px] text-center h-[100%] bg-white">
          {!editable ? (
            <>
              <p className="w-[20%]">{name}</p>
              {email && <p className="w-[20%]">{editedEmail}</p>}
              {plan && <p className="w-[20%]">{plan}</p>}
            </>
          ) : (
            <>
              <input className="w-[20%] text-center" value={name}></input>
              {email && (
                <input
                  className="w-[20%] text-center"
                  value={editedEmail}
                  onChange={(e) => {
                    setEditedEmail(e.target.value);
                  }}
                ></input>
              )}
              {plan && <p className="w-[20%] text-center">{plan}</p>}
            </>
          )}

          <div className="flex justify-evenly itmes-center w-[30%]">
            <div
              className="w-[24px] h-[24px] w-[50%] flex justify-center items-center cursor-pointer"
              onClick={editClient}
            >
              {icon1 !== undefined && icon1()}
            </div>
            <div
              className="w-[24px] h-[24px] w-[50%] flex justify-center items-center cursor-pointer"
              onClick={deleteClient}
            >
              {icon2 !== undefined && icon2()}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Client;
