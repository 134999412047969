import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Button from "../../../Button/Button";
import TextInput from "../../../TextInput/TextInput";

function ContactUs() {
  return (
    <div className="w-[100%] h-screen flex items-center justify-start flex-col mt-8">
      <div className="flex items-center justify-end w-[90%]">
        <Navbar />
      </div>
      <div className="h-[100%] w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-bold text-3xl mb-8">Contact Us</h1>
        <div className="mt-10 flex w-[100%] flex-col items-center justify-center">
          <div className="laptop:w-[90%] laptop:text-left text-center flex justify-center items-center flex-col w-[100%]">
            <p className="mt-8">
              Luft & Sons Farm Drainage Inc. proudly serves Iowa and beyond. Our
              team is here to provide you with effective solutions for enhancing
              your land’s productivity. We complete tiling, demo, and
              landscaping projects across Iowa and neighboring states. Our 75
              years of generational experience leaves us eager and ready to help
              with any project you have in mind.
            </p>
            <p className="mt-8">
              Ready to dive into the nitty-gritty of your next project? We
              provide free consultations and land assessments. Our unique
              expertise can help you improve your land by increasing its value
              and productivity. Email us at office@luftandsons.com or use the
              contact form below. Let us know how we can help you.
            </p>
          </div>
          <div className="mt-10 flex items-center justify-between laptop:w-[60%] laptop:flex-row flex-col">
            <div className="text-center">
              <p>Address:</p>
              <p>904 W. Elm Ave.</p>
              <p>Pocahontas, IA, 50574</p>
            </div>
            <div className="text-center laptop:mt-0 mt-8">
              <p>Hours</p>
              <p>Monday-Friday: 7 AM—7 PM</p>
              <p>Saturday: 8 AM—1 PM</p>
              <p>Sunday: Closed</p>
            </div>
          </div>
          <div>
            <p className="mt-8 laptop:text-left text-center">
              Ready to talk NOW? Drop us a line! Call us at 712-358-1438, and
              speak with us today. We look forward to hearing from you!
            </p>
          </div>
          <div className="flex items-center justify-center mt-10 flex-col border-black laptop:mb-20 mb-[120px]">
            <div className="flex justify-between items-end laptop:flex-row flex-col">
              <TextInput
                label="Name"
                placeholder=" First Name"
                labelClass="text-black"
                className="flex-col"
              />
              <TextInput
                placeholder=" Last Name"
                className="ml-10 laptop:mt-0 mt-2"
              />
            </div>
            <div className="flex justify-between items-end mt-10  laptop:flex-row flex-col">
              <TextInput
                label="Email"
                placeholder=" example@gmail.com"
                labelClass="text-black"
                className="flex-col"
              />
              <TextInput
                label="Phone"
                placeholder=" 0123.."
                labelClass="text-black"
                className="flex-col ml-10 laptop:mt-0 mt-8"
              />
            </div>
            <div className="flex justify-end items-center mt-10 flex-col justify-center items-start laptop:w-[100%]">
              <p className="w-[100%] text-center">Message</p>
              <textarea cols="36" rows="5"></textarea>
            </div>
            <Button
              text="Send"
              className="w-[150px] mt-10"
              buttonClass="border-black border-2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
