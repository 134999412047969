import React from "react";
import Navbar from "../../Components/Navbar/Navbar";
import { data } from "./knowledgebaseData";
import Video from "../../Components/Video/Video";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { IoArrowDown } from "react-icons/io5";

const parseDetails = (details) => {
  if (typeof details === "string") {
    return <p>{details}</p>;
  }

  if (Array.isArray(details)) {
    return details.map((detail, index) => (
      <span key={index} dangerouslySetInnerHTML={{ __html: detail }} />
    ));
  }

  return null;
};

function KnowledgeBase() {
  return (
    <div className="w-[100%] h-[100%] flex items-center justify-start flex-col mt-8 mb-4">
      <div className="flex items-center justify-end w-[90%]">
        <Navbar />
      </div>
      <div className="h-[100%] w-[100%] flex items-center flex-col mt-8">
        <h1 className="font-bold text-3xl mb-4">Knowledge Base</h1>
        <div className="mt-10 flex justify-center items-center w-[80%] flex-col">
          <Video
            title="video title"
            description="video description"
            className="w-[380px] h-[330px] m-4"
          />
          {data.map((e) => (
            <Accordion className="w-[100%] m-[20px]">
              <AccordionSummary
                expandIcon={<IoArrowDown />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>{e.summary}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{parseDetails(e.details)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>
    </div>
  );
}

export default KnowledgeBase;
