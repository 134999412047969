import React from "react";

function Button({ className, text, buttonClass, icon, onClick }) {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      {icon !== undefined ? (
        <button
          onClick={() => {
            if (onClick !== undefined) onClick();
          }}
          className={`rounded-full h-8 w-[60%] bg-white relative text-left pl-3 ${buttonClass} `}
        >
          {text}
          <div
            className={
              "w-min text-black bg-white absolute right-[8%] bottom-[18%]"
            }
          >
            {icon()}
          </div>
        </button>
      ) : (
        <button
          onClick={() => {
            if (onClick !== undefined) onClick();
          }}
          className={`rounded-full h-8 w-[100%] relative ${buttonClass} `}
        >
          {text}
        </button>
      )}
    </div>
  );
}

export default Button;
