import React, { useState, useRef, useEffect } from "react";
import Menu from "../../../Menu/Menu";
import Button from "../../../Button/Button";
import { Button as muiButton } from "@mui/material/Button";
import TextInput from "../../../TextInput/TextInput";
import { BiCube } from "react-icons/bi";
import { IoCloseSharp } from "react-icons/io5";
import Snackbar from "@mui/material/Snackbar";
import {
  AiOutlineUpload,
  AiOutlineFilePdf,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
} from "react-icons/ai";
import LoadingIcons from "react-loading-icons";
import {
  MAPDONE,
  MAPERROR,
  NOCHOSENINPUT,
  NOCHOSENLINE,
  PROCESSINGFILE,
  UPLOADINGFILE,
} from "../../../../constants";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ClientDashboard({
  isOpen,
  getValues,
  lines,
  PDF,
  setHilightedLine,
  hilightedLine,
  setInputs: setDashboardInputs,
}) {
  const [dashboard, setDashboard] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [previousInputs, setPreviousInputs] = useState(null);
  const [chosenPreviousInput, setChosenPreviousInput] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [snackbarDone, setSnackbarDone] = useState(false);
  const [snackbarMessage, setSnackBarMessage] = useState(MAPDONE);
  const [placeholders, setPlaceholders] = useState({});
  const [message, setMessage] = useState(UPLOADINGFILE);

  const navigate = useNavigate();

  // inputs
  const [inputs, setInputs] = useState({});

  // refs
  const hiddenFileInput = useRef(null);
  const timeoutIdRef = useRef(null);
  const refs = {
    name: useRef(),
    optimal_depth: useRef(),
    max_depth: useRef(),
    min_depth: useRef(),
    outlet_to_optimal: useRef(),
    optimal_min_percent: useRef(),
    optimal_max_percent: useRef(),
    bad_min_percent: useRef(),
    bad_max_percent: useRef(),
    shallow_max_percent: useRef(),
    peak_percent: useRef(),
  };

  // output
  const [errors, setErrors] = useState();

  useEffect(() => {
    const latestInput = getLatestInput();
    if (latestInput !== null && Object.keys(latestInput).length !== 0) {
      const newPlaceholders = {};
      for (const [key, value] of Object.entries(latestInput)) {
        if (key === "file") continue;
        newPlaceholders[key] = `${key}: ${value}`;
      }
      setPlaceholders(newPlaceholders);
    }
  }, [previousInputs]);

  function handlePlaceholder(key) {
    return Object.keys(placeholders).length !== 0 &&
      placeholders[key] != undefined
      ? placeholders[key]
      : key.replace(/_/g, " ");
  }

  useEffect(() => {
    if (previousInputs && previousInputs.length >= 1) {
      const latestInput = getLatestInput();
      setInputs({
        file: null,
        name: latestInput["name"],
      });
    }
    // eslint-disable-next-line
  }, [previousInputs]);

  useEffect(() => {
    getPreviousInputs();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!chosenPreviousInput) return;
    const foundObject = previousInputs.filter((input) => {
      // eslint-disable-next-line
      return input.id == chosenPreviousInput;
    })[0];
    if (foundObject) setDashboardInputs(foundObject);
    // eslint-disable-next-line
  }, [chosenPreviousInput]);

  useEffect(() => {
    if (chosenPreviousInput !== null) getValues(chosenPreviousInput);
    // eslint-disable-next-line
  }, [chosenPreviousInput]);

  function resetInputs() {
    setErrors(null);
    getPreviousInputs();
  }

  async function getPreviousInputs() {
    const url = `${process.env.REACT_APP_API_URL}/data/inputs/`;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
    };
    fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => {
        setPreviousInputs(data.body);
      });
  }
  function getLatestInput() {
    if (!previousInputs || previousInputs.length < 1) return null;
    return previousInputs.reduce(
      (max, input) => (input.id > max.id ? input : max),
      previousInputs[0]
    );
  }

  async function checkOnDataBeingProcessed() {
    if (!previousInputs || previousInputs.length < 0) return;

    const inputBeingProcessed = getLatestInput();
    if (inputBeingProcessed.error) {
      setSnackBarMessage(
        inputBeingProcessed.error_description + ". " + MAPERROR
      );
      setSnackbarDone(true);
      setProcessing(false);
      return;
    }
    if (processing && inputBeingProcessed.ran) {
      setSnackBarMessage(MAPDONE);
      setSnackbarDone(true);
      setProcessing(false);
    }
    if (!inputBeingProcessed || inputBeingProcessed.ran) return;
    setMessage(PROCESSINGFILE);
    setProcessing(true);
    const timeout = 6 * 1000; // each six seconds

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      getPreviousInputs();
    }, timeout);
  }

  useEffect(() => {
    checkOnDataBeingProcessed();
    // eslint-disable-next-line
  }, [previousInputs]);

  async function getDashboardData() {
    setProcessing(true);
    setMessage(UPLOADINGFILE);
    const url = `${process.env.REACT_APP_API_URL}/data/inputs/`;
    let formData = new FormData();
    formData.append("xml_file", inputs.file);
    formData.append("name", inputs.name);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "JWT " + localStorage.getItem("accessToken"),
      },
      body: formData,
    };
    setFetchingData(true);
    fetch(url, requestOptions)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((data) => {
        setFetchingData(false);
        if (data.status !== 200) {
          setErrors(data.body);
          return;
        }
        resetInputs();
        getPreviousInputs();
      });
  }

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  const handleUploadedFile = (event) => {
    setInputs((prevState) => ({
      ...prevState,
      file: event.target.files[0],
    }));
  };

  const handleInputChange = (key, value) => {
    setInputs((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handlePreviousInput = (e) => {
    // eslint-disable-next-line
    if (e.target.value && e.target.value !== NOCHOSENINPUT) {
      setHilightedLine(NOCHOSENLINE);
      setChosenPreviousInput(e.target.value);
    }
  };

  function openMenu() {
    return (
      <div className="absolute left-[20px] z-40 top-0 h-screen flex items-center justify-center">
        <div className="laptop:w-[42px] w-[32px] absolute top-0 laptop:right-[-18px] right-[-13px] z-30 h-[100%] flex justify-end items-center">
          <AiOutlineMenuFold
            id="button"
            className="block z-30 bg-black text-white p-[8px] laptop:w-[42px] laptop:h-[42px] w-[32px] h-[32px]  rounded-full"
            onClick={() => setDashboard(false)}
          />
        </div>
        <Menu className="rounded-2xl min-w-[300px] w-[300px] text-white h-[95%]">
          <div className="w-[100%] h-[100%] flex justify-start items-center flex-col z-20 no-scrollbar overflow-x-hidden">
            <h1 className="text-white text-2xl mt-5 mb-10">FieldFlow360</h1>
            <div className="w-[100%] m-1 flex justify-between flex-col text-black mb-5">
              <div>
                {PDF ? (
                  <Button
                    text="Download PDF"
                    onClick={() => {
                      const downloadPDF = PDF();
                      if (downloadPDF) downloadPDF();
                    }}
                    icon={() => <AiOutlineFilePdf />}
                  />
                ) : (
                  <Button text="No data" icon={() => <AiOutlineFilePdf />} />
                )}
              </div>
            </div>
            <h1>Inputs</h1>
            <div className="w-[100%] m-1 flex items-center justify-between flex-col text-black">
              <Button
                className="w-[100%]"
                text="Upload File"
                icon={() => <AiOutlineUpload />}
                onClick={handleClick}
                file="file"
                id="test"
              />
              <TextInput
                className="flex-col w-[50%]"
                inputClass="hidden"
                errorMsg={errors?.xml_file}
              />
              <input
                type="file"
                accept=".xml"
                ref={hiddenFileInput}
                className="hidden"
                onChange={handleUploadedFile}
              ></input>
            </div>
            <div className="w-[80%] m-1 flex justify-between flex-col mb-5">
              {inputs && (
                <TextInput
                  key={"name"}
                  ref={refs["name"]}
                  className="flex-col"
                  inputClass="rounded-full h-[30px]"
                  placeholder={handlePlaceholder("name")}
                  iconRight={() => <BiCube className="mb-1 mr-1" />}
                  errorMsg={
                    errors && errors[0] === "Name already exists"
                      ? errors && errors[0]
                      : errors?.["name"]
                  }
                  value={inputs?.["name"]}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              )}
              {fetchingData || processing ? (
                <div className="flex items-center justify-evenly">
                  <h1>Processing Data</h1>
                  <LoadingIcons.TailSpin className="h-[30px]" />
                </div>
              ) : (
                <Button
                  className="text-black mt-2"
                  text="Submit"
                  buttonClass="bg-white"
                  onClick={getDashboardData}
                />
              )}
            </div>
            <h1>Lines</h1>
            <div className="w-[80%] m-1 flex align-center justify-between flex-col mb-5">
              <select
                className="text-black"
                onChange={(e) => {
                  if (
                    hilightedLine !== e.target.value &&
                    e.target.value !== NOCHOSENLINE
                  )
                    setHilightedLine(e.target.value);
                }}
                value={hilightedLine}
                defaultValue={NOCHOSENLINE}
              >
                <option disabled value={NOCHOSENLINE}>
                  select an option
                </option>
                {lines !== undefined ? (
                  lines.map((element, index) => {
                    return (
                      <option key={element.line_id} value={index}>
                        {element.line_name}
                      </option>
                    );
                  })
                ) : (
                  <option value={NOCHOSENLINE} disabled>
                    no lines
                  </option>
                )}
              </select>
            </div>
            <h1>Previous Inputs</h1>
            <div className="w-[80%] m-1 flex align-center justify-between flex-col mb-[100px]">
              <select
                className="text-black"
                onChange={handlePreviousInput}
                defaultValue={NOCHOSENINPUT}
              >
                <option disabled value={NOCHOSENINPUT}>
                  select an option
                </option>
                {previousInputs !== null ? (
                  previousInputs
                    .sort((a, b) => b.id - a.id)
                    .map((element, index) => {
                      if (element.ran) {
                        return (
                          <option key={index} value={element.id}>
                            {element.name}
                          </option>
                        );
                      } else if (element.error) {
                        return (
                          <option key={index} value={null} disabled>
                            {element.name + " error"}
                          </option>
                        );
                      } else {
                        return (
                          <option key={index} value={null} disabled>
                            {element.name + " processing..."}
                          </option>
                        );
                      }
                    })
                ) : (
                  <option value={NOCHOSENINPUT}>no inputs</option>
                )}
              </select>
            </div>
          </div>
        </Menu>
      </div>
    );
  }

  function closeMenu() {
    return (
      <div
        id="button"
        className="w-[48px] h-[48px] absolute top-0 left-[12px] z-30 flex justify-start items-center h-screen"
      >
        <AiOutlineMenuUnfold
          id="button"
          className="block z-30 bg-black text-white p-[8px] laptop:w-[42px] laptop:h-[42px] w-[32px] h-[32px] rounded-full"
          onClick={() => setDashboard(true)}
        />
      </div>
    );
  }

  function menuState() {
    if (isOpen !== undefined) {
      if (isOpen) {
        return openMenu();
      } else {
        return closeMenu();
      }
    }
    if (dashboard) {
      return openMenu();
    } else {
      return closeMenu();
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className="cursor-pointer"
        open={processing}
        message={message}
        key={"snackbar"}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        className="cursor-pointer"
        open={snackbarDone}
        onClick={() => {
          if (snackbarMessage === MAPDONE) {
            const latestInput = getLatestInput();
            if (latestInput.error) return;
            setChosenPreviousInput(latestInput.id);
            setSnackbarDone(false);
          } else {
            setSnackbarDone(false);
            navigate("/KnowledgeBase");
          }
        }}
        key={"snackbarDone"}
      >
        <Alert
          severity={snackbarMessage === MAPDONE ? "success" : "error"}
          variant="filled"
          action={
            <div
              className="h-[100%] flex items-center justify-center mb-[6px]"
              onClick={(e) => {
                e.stopPropagation();
                setSnackbarDone(false);
              }}
            >
              <IoCloseSharp width={"18px"} height={"18px"} />
            </div>
          }
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {menuState()}
    </>
  );
}

export default ClientDashboard;
